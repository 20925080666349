module.exports = {
    BACKEND_UTILS: "https://utils.server.devagil.com",
    socketUrl: "https://socket.server.devagil.com",
    LAMBDA_POS: 'https://lambda.devagil.com/dev-pos',
    LAMBDA_AUTH: 'https://lambda.devagil.com/dev-auth',
    LAMBDA_SHOP: 'https://lambda.devagil.com/dev-shop',
    LAMBDA_COMMON: 'https://lambda.devagil.com/dev-common',
    LAMBDA_ADMIN: 'https://lambda.devagil.com/dev-admin',
    LAMBDA_AGIL: "https://lambda.devagil.com/dev-agil",
    AppKey: 'e939be0a35afb7d219b034d740c9f803',
    API_KEY_MAPS: 'AIzaSyCETkB78xH2peosxbPxCW7fiHxg0jxAXoM',
    SENTRY_DSN: 'https://cfab122337db492381bf4cfcfdd553e3@o454113.ingest.sentry.io/5443650',
    APP_ENV: 'dev',
    URL_DOWNLOAD_AGIL_POS_EXE: 'https://agil.s3.us-east-2.amazonaws.com/pos-local-socket/AGIL-POS.exe',
    AUTH_URL: "https://account.devagil.com",
    INTERCOM_APP_ID: 'nxzfys1q',
    HOTJAR_HJID: 2450215,
    HOTJAR_HJSV: 6,
    MAPBOX_TOKEN: 'pk.eyJ1IjoiYWdpbG1hcCIsImEiOiJjbG1rbzY2amkwM3NoMmttb3dtMHRteTRkIn0.6gAtqofNy3MsffYN6ml4SQ',
}
